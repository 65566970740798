import React, { useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { productsApi } from "../../api/api";
import ModalSuccess from "../ModalVacancy/ModalSuccess";
import googleIcon from "../../assets/img/icons/google.svg";
import yandexIcon from "../../assets/img/icons/yandexIcon.svg";
import { Field } from "formik";

const Footer = () => {
  const phoneRefInput = useRef(null);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [valInput, setValInput] = useState("");
  const [isPolitic, setIsPolitic] = useState(false);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    return () => {
      setDate(0);
    };
  }, []);

  return (
    <>
      <ModalSuccess
        isSuccessModal={isSuccessModal}
        setIsSuccessModal={setIsSuccessModal}
      />

      <footer className="footer">
        <div className="container">
          <Link to="/">
            <svg
              className="footer__logo"
              width="170"
              height="28"
              viewBox="0 0 134 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 20.9688C1.17708 20.8438 1.9375 20.6354 2.28125 20.3438C2.63542 20.0417 2.8125 19.2865 2.8125 18.0781V3.84375C2.8125 2.70833 2.64062 1.96875 2.29688 1.625C1.95312 1.27083 1.1875 1.05208 0 0.96875V0.375H17L17.1094 4.96875H16.2969C16.026 3.55208 15.6198 2.63542 15.0781 2.21875C14.5365 1.80208 13.3281 1.59375 11.4531 1.59375H7.10938C6.64062 1.59375 6.34896 1.67188 6.23438 1.82812C6.13021 1.97396 6.07812 2.25521 6.07812 2.67188V9.8125H11C12.3646 9.8125 13.2344 9.60938 13.6094 9.20312C13.9844 8.79688 14.2865 7.97396 14.5156 6.73438H15.2656V14.1406H14.5156C14.276 12.901 13.9688 12.0833 13.5938 11.6875C13.2188 11.2812 12.3542 11.0781 11 11.0781H6.07812V19C6.07812 19.6354 6.27604 20.0104 6.67188 20.125C7.07812 20.2396 8.28125 20.2969 10.2812 20.2969C12.4479 20.2969 14.0469 20.0625 15.0781 19.5938C16.1094 19.1146 17.0365 17.9635 17.8594 16.1406H18.75L17.2969 21.5469H0V20.9688Z"
                fill="white"
              />
              <path
                d="M28.2344 0.375V0.96875C27.4323 0.979167 26.8854 1.03125 26.5938 1.125C26.0833 1.29167 25.8281 1.63021 25.8281 2.14062C25.8281 2.40104 25.9167 2.76562 26.0938 3.23438C26.2812 3.70312 26.625 4.48958 27.125 5.59375L32.125 16.7344L36.6719 4.67188C36.8698 4.14062 37.0208 3.69271 37.125 3.32812C37.2292 2.96354 37.2812 2.66667 37.2812 2.4375C37.2812 1.82292 37.0156 1.40625 36.4844 1.1875C36.1719 1.0625 35.6562 0.989583 34.9375 0.96875V0.375H41.4844V0.921875C40.8073 1.01562 40.3073 1.19792 39.9844 1.46875C39.4948 1.875 39.0104 2.69271 38.5312 3.92188L31.4531 21.9219H30.9688L23.0781 4.32812C22.4115 2.83854 21.875 1.91146 21.4688 1.54688C21.0729 1.18229 20.4896 0.973958 19.7188 0.921875V0.375H28.2344Z"
                fill="white"
              />
              <path
                d="M52.4844 13.3281L48.7812 4.51562L45.125 13.3281H52.4844ZM38.6719 21.5469V20.9688C39.4948 20.875 40.1094 20.5625 40.5156 20.0312C40.9323 19.5 41.6406 18.0573 42.6406 15.7031L49.3125 0H49.9375L57.9062 18.1406C58.4375 19.349 58.8594 20.099 59.1719 20.3906C59.4948 20.6719 60.0312 20.8646 60.7812 20.9688V21.5469H52.6406V20.9688C53.5781 20.8854 54.1823 20.7865 54.4531 20.6719C54.724 20.5469 54.8594 20.25 54.8594 19.7812C54.8594 19.625 54.8073 19.349 54.7031 18.9531C54.599 18.5573 54.4531 18.1406 54.2656 17.7031L52.9375 14.625H44.5625C43.7292 16.7188 43.2292 18 43.0625 18.4688C42.9062 18.9271 42.8281 19.2917 42.8281 19.5625C42.8281 20.1042 43.0469 20.4792 43.4844 20.6875C43.7552 20.8125 44.2656 20.9062 45.0156 20.9688V21.5469H38.6719Z"
                fill="white"
              />
              <path
                d="M63.5706 20.9688C64.8935 20.8333 65.7425 20.5365 66.1175 20.0781C66.4925 19.6094 66.68 18.5312 66.68 16.8438V2.73438L66.2425 2.21875C65.7737 1.66667 65.3727 1.32812 65.0394 1.20312C64.7165 1.06771 64.2269 0.989583 63.5706 0.96875V0.375H69.055L81.3831 15.7812V5.07812C81.3831 3.31771 81.1227 2.17188 80.6019 1.64062C80.2581 1.29688 79.4977 1.07292 78.3206 0.96875V0.375H85.8362V0.96875C84.6175 1.09375 83.805 1.39062 83.3987 1.85938C82.9925 2.32812 82.7894 3.40104 82.7894 5.07812V21.9219H82.2425L68.0862 4.375V16.8438C68.0862 18.5938 68.3415 19.7344 68.8519 20.2656C69.1852 20.6094 69.93 20.8438 71.0862 20.9688V21.5469H63.5706V20.9688Z"
                fill="white"
              />
              <path
                d="M88.6412 0.375H106.719L106.891 5.8125H106.126C105.751 4.10417 105.271 2.99479 104.688 2.48438C104.115 1.96354 102.902 1.70312 101.047 1.70312H99.2662V18.0781C99.2662 19.3177 99.4589 20.0885 99.8444 20.3906C100.23 20.6823 101.074 20.875 102.376 20.9688V21.5469H93.0475V20.9688C94.4016 20.8646 95.2454 20.6406 95.5787 20.2969C95.9121 19.9531 96.0787 19.0885 96.0787 17.7031V1.70312H94.2975C92.5266 1.70312 91.3183 1.95833 90.6725 2.46875C90.0371 2.97917 89.5579 4.09375 89.235 5.8125H88.4537L88.6412 0.375Z"
                fill="white"
              />
              <path
                d="M120.595 0.375V0.96875C119.845 0.947917 119.282 1.00521 118.907 1.14062C118.532 1.27604 118.345 1.56771 118.345 2.01562C118.345 2.15104 118.376 2.32292 118.439 2.53125C118.501 2.72917 118.642 2.99479 118.861 3.32812L123.595 10.4375L128.189 3.21875C128.376 2.91667 128.496 2.66667 128.548 2.46875C128.611 2.27083 128.642 2.125 128.642 2.03125C128.642 1.57292 128.397 1.27083 127.907 1.125C127.616 1.03125 127.116 0.979167 126.407 0.96875V0.375H133.407V0.96875C132.762 1 132.184 1.19271 131.673 1.54688C130.881 2.09896 129.991 3.125 129.001 4.625L124.267 11.8438V18.0781C124.267 19.3177 124.465 20.0938 124.861 20.4062C125.267 20.7083 126.168 20.8958 127.564 20.9688V21.5469H117.782V20.9688C119.23 20.8854 120.126 20.6719 120.47 20.3281C120.824 19.974 121.001 19.099 121.001 17.7031V12.1406L116.814 6C115.366 3.88542 114.35 2.53125 113.767 1.9375C113.184 1.33333 112.475 1.01042 111.642 0.96875V0.375H120.595Z"
                fill="white"
              />
            </svg>
          </Link>

          <div className="footer__info">
            <div className="footer__contacts">
              <h4 className="footer__contacts-title footer-title">Контакты</h4>

              <div className="footer__contacts-mail">
                <span className="footer__contacts-mail_title">
                  Вопросы, предложения?
                </span>
                <a
                  className="footer__contacts-mail_link"
                  href="mailto:director@evanty.ru"
                >
                  Написать директору
                </a>
              </div>

              <a href="tel:+78452295865" className="footer__contacts-tell">
                8 (800)-551-15-66
              </a>

              <a href="tel:+79297778607" className="footer__contacts-tell">
                +7 (929) 777-86-07
              </a>

              <div className="footer__contacts-adress">
                Россия, 410530, Саратовская область, пос. Дубки, а/я 30
              </div>

              <a
                href="mailto:info@evanty.ru"
                className="footer__contacts-email"
              >
                info@evanty.ru
              </a>
            </div>

            <div className="footer__time--mobile">
              <h4 className="footer__time-title footer-title">Время работы</h4>
              <span className="footer__time-work">ПН - ВС | 8:00 - 17:00</span>
            </div>

            <div className="footer__fabric">
              <h4 className="footer__fabric-title footer-title">О фабрике</h4>
              <div className="footer__links">
                <Link to="/aboutFabric" className="footer__fabric-link">
                  История фабрики
                </Link>
                <Link to="/vacancy" className="footer__fabric-link">
                  Вакансии
                </Link>
              </div>
            </div>

            <div className="footer__help">
              <h4 className="footer__help-title footer-title">Помощь</h4>
              <div className="footer__links">
                <Link to="/howOrder" className="footer__help-link">
                  Как заказать
                </Link>
                <Link to="/dealers" className="footer__help-link">
                  Дилеры
                </Link>
                <Link to="/cooperation" className="footer__help-link">
                  Сотрудничество
                </Link>
              </div>
            </div>

            <div className="footer__social">
              <h4 className="footer__social-title footer-title">Соцсети</h4>
              <div className="footer__links">
                {/* <a
                  href="https://www.instagram.com/evanty_fabrika/"
                  className="footer__social-link"
                >
                  Instagram
                </a>
                <a
                  href="https://www.facebook.com/evanty.russia/"
                  className="footer__social-link"
                >
                  Facebook
                </a> */}
                <a
                  href="https://www.youtube.com/channel/UCou6ExZq8UlBcxde1mX7Eow"
                  className="footer__social-link"
                >
                  Youtube
                </a>
                <a
                  href="https://vk.com/public211160998"
                  className="footer__social-link"
                >
                  Вконтакте
                </a>
                <a
                  href="https://t.me/+7GSKkh-wXHQxNDYy"
                  className="footer__social-link"
                >
                  Telegram
                </a>
              </div>
            </div>
          </div>

          <div className="footer__info">
            <div className="footer__time">
              <h4 className="footer__time-title footer-title">Время работы</h4>
              <span className="footer__time-work">ПН - ВС | 8:00 - 17:00</span>
            </div>

            <div className="footer__review">
              <h4 className="footer__review-title footer-title">Отзывы</h4>
              <div className="footer__review-cards">
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/%D0%AD%D0%B2%D0%B0%D0%BD%D1%82%D0%B8/@51.6709338,46.0804173,17.5z/data=!4m5!3m4!1s0x4114cc24d0179dc9:0x37734fbbd8bfdea5!8m2!3d51.6703215!4d46.0776824?hl=ru-RU"
                  className="footer__review-card"
                >
                  <img className="footer__review-icon" src={googleIcon} />
                </a>
                <a
                  target="_blank"
                  href="https://yandex.ru/maps/org/evanty/1377383683/?ll=46.070907%2C51.665750&z=16.07"
                  className="footer__review-card"
                >
                  <img className="footer__review-icon" src={yandexIcon} />
                </a>
              </div>
            </div>
          </div>

          <div className="footer__consultation">
            <div className="footer__consultation-wrapper">
              <h4 className="footer__consultation-title footer-title">
                Консультация
              </h4>
              <p className="footer__consultation-text">
                <span> Хотите получить консультацию фабрики?</span>
                <span>Оставьте свой номер телефона и мы вам перезвоним!</span>
              </p>
            </div>

            <div className="footer__consultation-tel">
              <div className="footer__consultation-box">
                <NumberFormat
                  getInputRef={phoneRefInput}
                  className="consultation-tel__input"
                  placeholder="Телефон"
                  format="+7 ### ### ####"
                  mask=" "
                  value={valInput}
                  onValueChange={(val) => {
                    setValInput(val.value);
                    if (val.value.length === 10) {
                      setIsCorrect(true);
                    } else {
                      setIsCorrect(false);
                    }
                  }}
                />
                <button
                  className="consultation-tel__btn"
                  type="button"
                  disabled={!isPolitic}
                  onClick={() => {
                    if (valInput.length === 10) {
                      productsApi
                        .postPhoneFooter(
                          JSON.stringify({ phone: `+7${valInput}` })
                        )
                        .then(() => {
                          if (isCorrect) {
                            setValInput("");
                            setIsSuccessModal(true);
                            setIsPolitic(false);
                            phoneRefInput.current.style.borderColor = "#C7C7C7";
                          } else {
                            phoneRefInput.current.style.borderColor = "red";
                          }
                        });
                    }
                  }}
                >
                  <img
                    src={require("../../assets/img/icons/footer__arrow.svg")}
                    alt=""
                    className="consultation-tel__btn--arrow"
                  />
                </button>
              </div>
              <div className="feedback__politic">
                <input
                  type="checkbox"
                  name="politicTell"
                  className="feedback__politic-check"
                  id="data-politicTell"
                  checked={isPolitic}
                  onChange={(e) => setIsPolitic(e.target.checked)}
                />
                <label
                  htmlFor="data-politicTell"
                  className="feedback__politic-title"
                >
                  <a
                    href="https://evanty.ru/upload/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B0_%D0%B2_%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
                    download
                  >
                    Даю согласие на обработку моих персональных данных в
                    соответствии с 152-ФЗ
                  </a>
                </label>
              </div>
            </div>
          </div>

          <div className="footer-main">
            <div className="footer-main__docs--mobile">
              <a href="/" className="footer-main__link">
                Пользовательское соглашение
              </a>
              <a href="/" className="footer-main__link">
                Политика конфиденциальности
              </a>
            </div>
            <div className="footer-main__year">{`© 1999-${date?.getUTCFullYear()} Evanty`}</div>
            <a
              href="/"
              className="footer-main__link footer-main__link--desktop"
            >
              Пользовательское соглашение
            </a>
            <a
              href="/"
              className="footer-main__link footer-main__link--desktop"
            >
              Политика конфиденциальности
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
