import React, { useEffect, useState } from "react";
import { howOrder } from "./../api/api";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import Shops from "./../components/Shops/Shops";
import FeedbackForm from "./../components/HowOrder/FeedbackForm/FeedbackForm";
import OriginalShop from "./../components/HowOrder/OriginalShop/OriginalShop";
import TermsItem from "./../components/Cooperation/TermsItem/TermsItem";

import "../components/Cooperation/Cooperation.scss";
import Preloader from "../components/Preloader/Preloader";
import Helmet from "react-helmet";
import { useWidthContext } from "../widthContext";

const Cooperation = () => {
  const [dataOriginalShop, setOriginalShop] = useState([]);
  const [dataDealersShop, setDealersShop] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { isMobile } = useWidthContext();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (!isLoaded) {
      document.body.style.overflow = "hidden";
    }
    Promise.all([
      howOrder.getOriginalShops().then((data) => setOriginalShop(data)),
      howOrder.getDealerShops().then((data) => setDealersShop(data[1].shops)),
    ]).then(() => setIsLoaded(true));
  }, []);

  useEffect(() => {
    if (isLoaded) {
      document.body.style.overflow = "";
    }
  }, [isLoaded]);

  return (
    <>
      <Preloader isLoaded={isLoaded} />

      <Helmet>
        <title>Сотрудничество с компанией</title>
        <meta name="description" content="Nested component" />
      </Helmet>

      <div className="cooperation">
        <div className="container">
          <Header color={"black"} />

          <h1 className="cooperation__title">Сотрудничество</h1>
          <div className="cooperation__subtitle">
            Компания Evanty предлагает владельцам магазинов мебели, дизайн
            студий, а также частным дизайнерам и архитекторам стать партнёром
            фабрики.
          </div>

          <div className="cooperation__wrapper">
            {isMobile ? (
              <Shops />
            ) : (
              <>
                <div className="original-shop">
                  <h2 className="original-shop__title">
                    Наши фирменные магазины
                  </h2>
                  <OriginalShop dataOriginalShop={dataOriginalShop} />
                </div>

                {/*<DealersShop dataDealersShop={dataDealersShop}/>*/}
              </>
            )}
          </div>

          <div className="cooperation__terms">
            <div className="cooperation__terms-list">
              <TermsItem
                title={"Дилерам"}
                desc={
                  "Официальным представителем мебельной фабрики Evanty может стать владелец или арендатор площади мебельного салона, заинтересованный в продаже и расширении ассортимента качественной мебели. Условия работы индивидуальны и устанавливаются в зависимости от специфики региона, объёма желаемой выставки продукции фабрики и других характеристик потенциального дилера"
                }
              />
              <TermsItem
                title={"Дизайнерам"}
                desc={
                  "Мы знаем, как важно найти для клиентов что-то надежное, долговечное и подходящее под бюджет. Свяжитесь с нами, чтобы договориться о встрече в салоне компании или узнать контакты представителя фабрики в вашем регионе для знакомства с мебелью Evanty лично."
                }
              />
            </div>
          </div>

          <div className="cooperation__feedback">
            <div className="cooperation__feedback-wrapper">
              <h1 className="cooperation__feedback-title">Оставьте заявку</h1>
              <div className="cooperation__feedback-desc">
                Чтобы узнать больше о сотрудничестве с Evanty
              </div>
              {isMobile ? (
                ""
              ) : (
                <div className="cooperation__contacts">
                  <div className="cooperation__contacts-desc">
                    Или свяжитесь с нами по телефону или письмом на почту
                  </div>
                  <a href="" className="cooperation__contacts-tell">
                    8 (800)-551-15-66
                  </a>
                  <a href="" className="cooperation__contacts-email">
                    office@evanty.ru
                  </a>
                </div>
              )}
            </div>

            <FeedbackForm />
            {isMobile ? (
              <div className="cooperation__contacts">
                <div className="cooperation__contacts-desc">
                  Или свяжитесь с нами по телефону или письмом на почту
                </div>
                <a href="" className="cooperation__contacts-tell">
                  8 (800)-551-15-66
                </a>
                <a href="" className="cooperation__contacts-email">
                  office@evanty.ru
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Cooperation;
